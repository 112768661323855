@import './../../styles/mixins/media';
@import './../../styles/mixins/colors';

.nb-header {
    text-align: center;
    display: block;

    &__title {
        padding: 0;
        margin: 0;
        margin-top: 25px;
        text-align: center;
    }

    &__item-group {
        display: block;

        @include medium-device {
            display: inline-block;
        }
    }

    &__menu {
        &__item {
            display: inline-block;
            margin: 0 5px;
            a {
                font-size: 1.5rem;
                text-decoration: none;
                background-color: none;
                color: #e54087;
                display: inline-block;
                border-radius: 5px;
                transition-duration: 0.3s;
                transform: translateY(0);
                font-weight: bold;
                width: 100%;
                padding: 8px;
                &:hover, &:active {
                    background-color: #252856;
                    transform: translateY(-5px);
                }
            }

            .nb-header__menu__item__link_text {
                display: none;
            }

            @media screen and (min-width: 768px) {
                display: inline-block;
                a {
                    display: inline-block;
                    width: auto;
                }

                i {
                    display: none;
                }

                .nb-header__menu__item__link_text {
                    display: inline-block;
                }
            }

            &--active {
                a {
                    background-color: #252856;
                    transform: translateY(-5px);
                }
            }
        }
    }
}