@import './../../styles/mixins/colors';

.nb-footer {
    text-align: center;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-background-primary-dark;

    &__item {
        display: block;
        margin: 10px;
    }
}